.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: darkgray;
}

.login-form {
    margin: 1rem;
}

.password-input {
    width: 20rem;
    padding: 0.5rem;
    border-radius: 5px;
}