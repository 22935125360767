table {
  font-family: arial, sans-serif;
  border-collapse:collapse;
  width: 50%;
  margin: 0 auto;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* Header of the table */
tr:nth-child(even) {
  background-color: #dddddd;
}

/* Add a hover effect to the rows */
tr:hover {
  background-color: #f5f5f5;
}


.status-circle {
    border-radius: 50%;
    float: left;
    width: 25px;
    height: 25px;
  }
  
  .status-circle-inner {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  
  .green {
    background-color: #3cb371;
  }
  
  .yellow {
    background-color: #ffed00;
  }
  
  .red {
    background-color: #f03e3e;
  }

  .gray {
    background-color: #999999;
  }

  .comment {
    color: #7e7e7e;
  }