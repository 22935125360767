.App {
  text-align: center;
}

.App-logo {
  height: 50%;
  margin-right: 10px;
}

.Title {
  margin-top: 25px;
}
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
.App-header {
  background-color: #282c34;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Status-section {
  align-items: center;
  flex-direction: column;
  justify-content: start;
  height: 90vh;
}

.App-link {
  color: #61dafb;
}
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/